<template>
  <section class="home">
    <article @click.prevent="$router.push('my-ip')">
My IP address
    </article>
    <article @click.prevent="$router.push('date-duration')">
Date duration
    </article>
  </section>
</template>

<script>

export default {
  name: 'Home'
}
</script>
<style scoped>
.home{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: 5px;
  justify-content: center;
}
.home article {
  flex: 0 0 400px;
  display: grid;
  grid-template-rows: repeat(auto-fill, fit-content(50px));
  grid-template-columns: repeat(auto-fill, fit-content(50px));
  grid-column-gap: 5px;
  box-shadow: 1px 1px 4px grey;
  padding: 10px;
  margin: 5px;
  border-radius: 2%;
}

.home article:hover{
  box-shadow: 1px 1px 4px navy;
  cursor: pointer;
}

</style>
