<template>
  <div id="nav" class="user-select-none">
    <router-link to="/">Home</router-link> |
    <router-link to="/my-ip">My IP address</router-link> |
    <router-link to="/date-duration">Date duration</router-link>
  </div>
    <router-view v-slot="{ Component }" max="5" class="content">
      <keep-alive>
        <component :is="Component"/>
      </keep-alive>
    </router-view>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #08a5e0;
}

#nav a:hover {
  color: #08a5e0;
}

#nav + div{
  padding: 10px;
}

.user-select-none{
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.content {
  margin: 0 15px;
}
</style>
