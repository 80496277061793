import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/my-ip',
    name: 'MyIPAdd',
    component: () => import('../views/MyIpPage.vue')
  },
  {
    path: '/date-duration',
    name: 'Dates',
    component: () => import('../views/DatePage.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'Home' }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
